<template>
  <span>
    <span v-if="hours > 0"> {{ hours }} Hours </span>
    <span v-if="minutes > 0"> {{ minutes }} Mins </span>
    <span v-if="minutes == 0"> {{ seconds }} Secs </span>
    ago
  </span>
</template>

<script>
export default {
  props: ["since"],
  data() {
    return {
      time: new Date()
    };
  },
  mounted() {
    setInterval(() => (this.time = new Date()), 1000);
  },
  computed: {
    seconds() {
      return Math.floor(
        (this.time -
          new Date(this.since) -
          Math.floor((this.time - new Date(this.since)) / 1000 / 60) *
            60 *
            1000) /
          1000
      );
    },
    minutes() {
      return Math.floor(
        (this.time - new Date(this.since) - this.hours * 60 * 60 * 1000) /
          1000 /
          60
      );
    },
    hours() {
      return Math.floor((this.time - new Date(this.since)) / 1000 / 60 / 60);
    }
  }
};
</script>
