<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3">
        <v-text-field
          dense
          v-model="search"
          label="Search person by name"
          prepend-icon="search"
        ></v-text-field>
      </v-col>

      <v-spacer></v-spacer>
      <h1>{{ title }}</h1>
      <v-spacer></v-spacer>

      <v-dialog v-model="addDialog" width="500">
        <template v-slot:activator="{ on }">
          <v-col cols="3">
            <v-btn color="success" width="100%" v-on="on">
              Add new hardware
            </v-btn>
          </v-col>
        </template>
        <v-card>
          <v-card-title primary-title>
            Add New Hardware
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field
                name="ItemName"
                label="Item Name"
                v-model="newName"
              ></v-text-field>
              <v-text-field
                name="ItemAmount"
                type="number"
                label="Amount"
                v-model="newAmount"
              ></v-text-field>
              <v-text-field
                name="ItemPicture"
                label="Picture Link"
                v-model="newPicture"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="addHardware">
              Add
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog
      v-model="editDialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          Edit Hardware
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              name="ItemName"
              label="Item Name"
              v-model="tempEditHardware.name"
            ></v-text-field>
            <v-text-field
              name="ItemAmount"
              type="number"
              label="Amount"
              v-model="tempEditHardware.available"
            ></v-text-field>
            <v-text-field
              name="ItemPicture"
              label="Picture Link"
              v-model="tempEditHardware.pic"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            @click="
              editHardware = tempEditHardware;
              editHardware.available = parseInt(editHardware.available);
              $fiery.update(editHardware);
              editDialog = false;
            "
          >
            Change
          </v-btn>
          <v-btn color="error" @click="editDialog = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeDialog" width="500">
      <v-card>
        <v-card-title primary-title>
          Are you sure you want to delete {{ removeHardware.name }}?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            @click="
              removeDialog = false;
              $fiery.remove(removeHardware);
            "
          >
            Yes
          </v-btn>
          <v-btn color="warning" @click="removeDialog = false">
            No
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="scrolling-wrapper">
      <v-col
        style="flex: 0 0 auto;"
        :cols="11"
        :md="4"
        :lg="3"
        ma-1
        v-for="(h, i) of searchHardware"
        :key="i"
      >
        <v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon @click="edit(h)">
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
            <v-btn icon @click="showRemoveDialog(h)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
          <v-img :src="h.pic" height="100px" contain />
          <v-card-title primary-title>
            <v-spacer></v-spacer>
            <div class="headline">{{ h.name }}</div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col :cols="4" px-1>
                <v-chip style="width:100%" color="green" text-color="white">
                  <v-avatar class="green darken-3">{{ h.available }}</v-avatar>
                  <div class="ml-1">Available</div>
                </v-chip>
              </v-col>
              <v-col :cols="4" px-1>
                <v-chip style="width:100%" color="yellow" text-color="black">
                  <v-avatar class="yellow darken-3">{{ h.reserved }}</v-avatar>
                  <div class="ml-1">Reserved</div>
                </v-chip>
              </v-col>
              <v-col :cols="4" px-1>
                <v-chip style="width:100%" color="red" text-color="white">
                  <v-avatar class="red darken-3">{{ h.inUse }}</v-avatar>
                  <div class="ml-1">InUse</div>
                </v-chip>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-expansion-panels multiple :value="[1, 1, 0]">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>
                  <v-avatar class="blue-grey lighten-4">
                    <h3>
                      {{
                        h.searchReservations.filter(
                          r => !r.loaned && !r.returned
                        ).length
                      }}
                    </h3>
                  </v-avatar>
                </div>
                <h1>Reservations</h1>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col
                  v-for="(r, i) in h.searchReservations.filter(
                    r => !r.loaned && !r.returned
                  )"
                  :key="i"
                >
                  <v-card>
                    <v-card-text class="pa-2 pb-0">
                      <h2>Name: {{ r.name }}</h2>
                      <h3>Table: {{ r.table }}</h3>
                      <h3>Amount: {{ r.amount }}</h3>
                      <h4>Phone Number: {{ r.phone }}</h4>
                      <h4>
                        Reserved
                        <Time :since="r.reservedTime"></Time>
                      </h4>
                      <v-text-field
                        name="slot"
                        type="number"
                        label="Card Slot ID will be saved in:"
                        :ref="'slot' + i"
                        value="0"
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="loaned(h, r, i)" color="success">
                        Confirm
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="cancel(h, r)" color="error">Cancel</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>
                  <v-avatar class="blue-grey lighten-4">
                    <h3>
                      {{
                        h.searchReservations.filter(
                          r => r.loaned && !r.returned
                        ).length
                      }}
                    </h3>
                  </v-avatar>
                </div>
                <h1>Loans</h1>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col
                  v-for="(r, i) in h.searchReservations.filter(
                    r => r.loaned && !r.returned
                  )"
                  v-bind:key="i"
                >
                  <v-card>
                    <v-card-text>
                      <h2>Name: {{ r.name }}</h2>
                      <h2>Card Slot: {{ r.slot }}</h2>
                      <h3>Table: {{ r.table }}</h3>
                      <h4>Amount: {{ r.amount }}</h4>
                      <h4>Phone Number: {{ r.phone }}</h4>
                      <h4>
                        Loaned
                        <Time :since="r.loanTime"></Time>
                      </h4>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="returned(h, r)" color="warning">
                        Returned
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>
                  <v-avatar class="blue-grey lighten-4">
                    <h3>
                      {{
                        h.searchReservations.filter(
                          r => !r.loaned && r.returned
                        ).length
                      }}
                    </h3>
                  </v-avatar>
                </div>
                <h1>History</h1>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col
                  pa-1
                  v-for="(r, i) in h.searchReservations.filter(
                    r => !r.loaned && r.returned
                  )"
                  v-bind:key="i"
                >
                  <v-card>
                    <v-card-text>
                      <h2>Name: {{ r.name }}</h2>
                      <h3>Table: {{ r.table }}</h3>
                      <h4>Amount: {{ r.amount }}</h4>
                      <h4>Phone Number: {{ r.phone }}</h4>
                      <h4>
                        Reserved
                        <Time :since="r.reservedTime"></Time>
                      </h4>
                      <h4>
                        Loaned
                        <Time :since="r.loanTime"></Time>
                      </h4>
                      <h4>
                        Returned
                        <Time :since="r.returnTime"></Time>
                      </h4>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import db from "../firebase";
import Time from "../components/Time";

export default {
  props: ["title", "collection"],
  fiery: true,
  components: { Time },
  data() {
    return {
      search: "",
      editDialog: false,
      editHardware: {},
      tempEditHardware: {},
      removeHardware: {},
      addDialog: false,
      removeDialog: false,
      newName: "",
      newAmount: 0,
      newPicture: "",
      hardware: this.$fiery(db.collection(this.collection), {
        exclude: ["searchReservations"],
        sub: {
          reservations: {}
        }
      })
    };
  },
  computed: {
    searchHardware: function() {
      if (this.search === "") {
        this.hardware.forEach(h => (h.searchReservations = h.reservations));
        return this.hardware;
      } else {
        return this.hardware.filter(h => {
          h.searchReservations = h.reservations.filter(r =>
            r.name.includes(this.search)
          );
          return h.searchReservations.length > 0;
        });
      }
    }
  },
  methods: {
    returned(h, r) {
      r.returned = true;
      r.loaned = false;
      r.returnTime = new Date().toString();
      h.inUse -= r.amount;
      h.available += r.amount;
      this.$fiery.update(h);
      this.$fiery.update(r);
    },
    loaned(h, r, i) {
      r.loaned = true;
      r.loanTime = new Date().toString();
      if (i != null) {
        r.slot = parseInt(this.$refs["slot" + i][0].lazyValue);
      }
      h.reserved -= r.amount;
      h.inUse += r.amount;
      this.$fiery.update(h);
      this.$fiery.update(r);
    },
    cancel(h, r) {
      this.loaned(h, r, null);
      this.returned(h, r);
    },
    addHardware() {
      this.$fiery.create(this.hardware, {
        name: this.newName,
        available: parseInt(this.newAmount),
        pic: this.newPicture,
        inUse: 0,
        reserved: 0
      });
      this.addDialog = false;
    },
    showRemoveDialog(h) {
      this.removeHardware = h;
      this.removeDialog = true;
    },
    edit(h) {
      this.editDialog = true;
      this.tempEditHardware = JSON.parse(JSON.stringify(h));
      this.editHardware = h;
    }
  }
};
</script>

<style scoped>
.scrolling-wrapper {
  flex-wrap: nowrap;
  overflow-x: auto;
}
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
::-webkit-scrollbar-thumb:horizontal {
  background: #ccc;
  border-radius: 10px;
}
</style>
